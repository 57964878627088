<template>
    <div class="modules">
        <meo-ripple :config="config" v-if="$vuetify.breakpoint.smAndDown"></meo-ripple>

        <v-container :class="{ 'pa-16': $vuetify.breakpoint.smAndUp }">
            <v-row class="p-relative zindex-1 full-width ma-0">
                <v-col cols="12" align="center" justify="center">
                    <h3 class="heading--text">Mastering Emotional Health</h3>
                    <p class="muted-1 ma-0">21 ways to enhance your wellbeing</p>
                </v-col>
            </v-row>

            <v-row justify="center">
                <v-col cols="12" md="4" lg="3" v-for="item in modules" :key="item._id">
                    <template v-if="$vuetify.breakpoint.smAndDown">
                        <meo-list-item
                            :item="item"
                            :purchased="purchased(item)"
                            :completed="completed(item)"
                            @click.native="goTo(item)"
                        ></meo-list-item>
                    </template>

                    <template v-else>
                        <meo-card-item
                            :item="item"
                            :purchased="purchased(item)"
                            :completed="completed(item)"
                            @click.native="goTo(item)"
                        ></meo-card-item>
                    </template>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
// Mixins
import UserMixin from "@/mixins/user-mixin";
import SEOMixin from "@/mixins/seo-mixin";

// Components
import MeoRipple from "@/components/misc/meo-ripple.vue";
import MeoListItem from "@/components/misc/meo-list-item.vue";
import MeoCardItem from "@/components/misc/meo-card-item.vue";

//Services
// import Article from "@/modules/meo-module/article";
import MeoProgressDocument from "@/modules/meo-module/progress-document";

export default {
    name: "module-list",

    mixins: [SEOMixin],

    meta: {
        titleTemplate: "Sessions | %s",
    },

    data() {
        return {
            config: {
                image: require("@/assets/logo.png"),
                padding: true,
                // avatar: true,
            },
            modules: [],
            products: [],
            resources: [],
            progress: {},
        };
    },

    components: {
        MeoRipple,
        MeoListItem,
        MeoCardItem,
    },

    methods: {
        async init() {
            // this.resources = await Article.list().then(({ data }) => data);
            let criteria = {
                includeUnmatched: true,
                allDefinitions: true,
                select: ["data", "items"],
                sort: {
                    key: "title",
                    direction: "asc",
                    type: "string",
                },
                filter: {
                    operator: "and",
                    filters: [
                        {
                            key: "status",
                            comparator: "in",
                            values: ["active"],
                        },
                    ],
                },
            };

            // Filter out bundle
            let modules = await this.$fluro.content.filter("meoModule", criteria);
            modules = modules.filter((item) => item._id !== this.$meo.bundle);

            this.modules = modules.sort((a, b) => {
                let first = a.data && a.data.order;
                let second = b.data && b.data.order;

                return first - second;
            });
            // console.log(modules, "ALl the modules in INDEX");

            if (this.$meo.user) {
                await this.$fluro.resetCache();

                //get progressDoc
                let progressDocList = await MeoProgressDocument.list().then(({ data }) => data);
                this.progress = progressDocList?.[0];
                // console.log(this.progress, "progressDoc in Index...");

                let freeProducts = modules.filter((item) => item.data && item.data.freeProduct);
                // Get free product items and reattach
                let ids = [].concat.apply(
                    [],
                    freeProducts.map(({ items }) => items)
                );
                let freeItems = await this.$fluro.content.getMultiple("", ids, {});
                freeProducts.forEach((product) => {
                    let items = product.items || [];
                    freeItems.forEach((item) => {
                        if (items.includes(item._id)) {
                            let index = items.findIndex((indexItem) => indexItem == item._id);
                            product.items.splice(index, 1, item);
                        }
                    });
                });

                // Get products the user has access to
                let products = await this.$fluro.api.get("/my/products/meoModuleScreen").then(({ data }) => data);
                // All products with product.items
                this.products = products.concat(freeProducts);
            }
        },
        goTo(item) {
            let id;
            let match = false;

            if (item && item._id) id = item._id;
            else id = item;

            // Check if the user has access to the products
            if (this.products && this.products.length) {
                match = this.products.some((product) => product._id == id);
            } else {
                if (this.products && this.products._id && this.products._id === id) {
                    match = true;
                }
            }

            // This is a free product, overwrite previous condition
            if (item.data && item.data.freeProduct) {
                match = true;
            }

            if (match) this.$router.push({ name: "module.menu", params: { id } });
            else if (!this.$meo.user) this.$router.push({ name: "auth.signup", query: { session: id } });
            else this.$router.push({ name: "purchase", params: { id } });
        },
        purchased(item) {
            let match = this.products.some((product) => product._id === item._id);
            let free = item.data && item.data.freeProduct;

            if (match || free) return true;
            return false;
        },
        completed(item) {
            let progressDoc = this.progress;
            //   console.log(item);

            if (progressDoc?.data?.modules) {
                let currentModule = progressDoc?.data?.modules.find((module) => module.module._id == item._id);

                return this.compareProgress(currentModule?.screens, item.items);
            }

            /////////////// Local Storage Version /////////////////////////////////

            let localProgress = JSON.parse(window.localStorage.getItem("progress"));
            if (localProgress?.length) {
                let currentProgress = localProgress.find((progressObj) => progressObj.module._id == item._id);
                // console.log(currentProgress, "currentProgress");

                return this.compareProgress(currentProgress?.screens, item.items);
            }

            return false;
        },

        compareProgress(progress, totalScreens) {
            if (progress?.length && totalScreens?.length) {
                if (totalScreens?.length === progress?.length) {
                    return true;
                } else {
                    return false;
                }
            }
        },
    },

    async created() {
        this.$meo.loading = true;

        if (this.$meo.user) {
            await this.$meo.initialised();
        }

        await this.init();

        this.$meo.loading = false;
    },
};
</script>

<style lang="scss"></style>
